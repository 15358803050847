

import { Activity, ActualActivity, Phase, Program } from "@/types";
import { format } from "date-fns";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { nl, fr } from "date-fns/locale";
import { User } from "@/store/user/types";
import { USER_GETTERS } from "@/store/user/getters";
import { Timestamp } from "firebase/firestore";


@Component({
  components: {
    AddActualActivityDialog: () => import('@/components/dialogs/AddActualActivity.vue'),
    EditActualActivity: () => import('@/components/dialogs/EditActualActivity.vue'),
  }
})
export default class ProgramPhaseControls extends Vue {
  @Prop({ required: true }) program!: Program;
  @Prop({ required: true }) phases!: Phase[];
  @Prop({ required: true }) activities!: Activity[];
  @Prop({ required: true }) actualActivities!: ActualActivity[];

  get user(): User {
    return this.$store.getters[USER_GETTERS.ACTING_USER]
  }

  selectedActivity = '';
  selectedPhase = '';

  currentStep = 1;
  selectedActualActivity: ActualActivity | null = null;

  dialogs = {
    addActualActivity: false,
    editActualActivity: false,
  }

  openAddActualActivityDialog(activityId: string | undefined, phaseId: string | undefined) {
    if (activityId) {
      this.selectedActivity = activityId;
      this.selectedPhase = phaseId!;
      this.dialogs.addActualActivity = true;
    }
  }

  formatHours(hours: number) {
    let rhours = Math.floor(hours);

    let minutes = hours % 1;
    if (minutes === 0) return `${rhours}h`;

    let rminutes = Math.ceil(minutes * 60)

    return `${rhours}h${rminutes}m`
  }


  formatDate(date: Timestamp) {
    return format(date.toDate(), 'dd/MM/yyyy', {
      locale: this.user.language == 'fr' ? fr : nl,
    })
  }

  getActualHoursForActivity(activity: Activity) {
    const hoursArray = this.actualActivities
      .filter(e => e.activityId === activity.id)
      .map(e => e.actualHours)
      .map(e => {
        // Ensure each item is treated as a number
        const num = typeof e === 'string' ? parseFloat(e) : e;
        return isNaN(num) ? 0 : num; // Convert to 0 if it's not a valid number
      });

    // Calculate the total hours
    const totalHours = hoursArray.reduce((a, b) => a + b, 0);

    // Return the total hours formatted to 2 decimal places
    return totalHours.toFixed(2);
  }

  get currentPhase() {
    return this.phases.find(phase => phase.start.toDate() <= new Date() && phase.end.toDate() >= new Date())
  }

  async mounted() {
    // set current step to the current phase
    if (!this.currentPhase) return;
    this.currentStep = this.phases.findIndex(phase => phase.id === this.currentPhase?.id) + 1;
  }
}
